<template>
  <b-card class="mb-3" v-if="supportCacheStorage">
    <b-card-title title-tag="h5">
      <span>缓存管理</span>
      <b-icon-trash
        scale="0.8"
        class="ml-2"
        id="tooltip-delete"
        role="button"
        @click="clearCache"
      />
    </b-card-title>
    <b-tooltip target="tooltip-delete" triggers="hover">删除缓存</b-tooltip>
    <b-card-text>
      <tr>
        <td class="nowrap">缓存列表</td>
        <td class="pl-4">
          <div v-if="caches.length != 0">
            <span v-for="cache of caches" :key="cache">
              <code>{{ cache }}</code
              ><br />
            </span>
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="nowrap">已用空间</td>
        <td class="pl-4">
          {{ storage_str }}
        </td>
      </tr>
    </b-card-text>
  </b-card>
</template>

<script>
import { BIconTrash } from "bootstrap-vue";
import filesize from "filesize";
import { BTooltip } from "bootstrap-vue";

export default {
  name: "CacheStorageCard",
  data: () => ({
    caches: [],
    usage: 0,
    quota: 0,
  }),
  computed: {
    supportCacheStorage: () =>
      "caches" in window &&
      "open" in window.caches &&
      "match" in window.caches &&
      "keys" in window.caches,
    storage_str: function () {
      return this.quota === 0
        ? "N/A"
        : `${filesize(this.usage)} / ${filesize(this.quota)}`;
    },
  },
  mounted: async function () {
    this.update();
  },
  methods: {
    async update() {
      if (this.supportCacheStorage) {
        this.caches = await window.caches.keys();

        if (navigator?.storage?.estimate) {
          const { usage, quota } = await navigator.storage.estimate();
          this.usage = usage;
          this.quota = quota;
        }
      }
    },
    async clearCache() {
      if (this.supportCacheStorage) {
        const caches_keys = await caches.keys();
        for (const cache of caches_keys) {
          await caches.delete(cache);
        }
        this.$bvToast.toast(`成功删除 ${caches_keys}`, {
          title: "缓存删除成功",
          autoHideDelay: 5000,
          solid: true,
          variant: "success",
        });
        this.update();
      }
    },
  },
  components: {
    BIconTrash,
    BTooltip,
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>
