<template>
  <b-card class="mb-3" v-if="supportIndexedDbDatabases">
    <b-card-title title-tag="h5">
      <span>存档管理</span>
      <small class="ml-2"
        ><b-badge variant="secondary">em-dosbox</b-badge></small
      >
      <b-icon-trash
        scale="0.8"
        class="ml-2"
        id="tooltip-delete-emularity-save"
        role="button"
        @click="clearSaves"
      />
    </b-card-title>
    <b-tooltip target="tooltip-delete-emularity-save" triggers="hover">
      删除所有存档
    </b-tooltip>
    <b-card-text>
      <div v-if="saves.length !== 0">
        <tr v-for="save in saves" :key="save.key">
          <td>
            <b-icon-x
              :id="`tooltip-delete-emularity-${save.key}`"
              scale="0.8"
              class="mr-2"
              @click="deleteSave(save.key)"
              role="button"
            />
            <b-tooltip
              :target="`tooltip-delete-emularity-${save.key}`"
              triggers="hover"
            >
              删除
            </b-tooltip>
          </td>
          <td>
            {{ save.key }}
          </td>
          <td class="pl-4">
            <code>{{ save.size }}</code>
          </td>
        </tr>
      </div>
      <div v-else>暂无存档</div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BIconTrash, BIconX } from "bootstrap-vue";
import { BTooltip, BBadge } from "bootstrap-vue";
import filesize from "filesize";

import { getSaveKeys, getSaveSize, deleteSave, supportGetSaveKeys } from "@/utils/emularity";

export default {
  name: "EmularitySaveCard",
  data() {
    return {
      saves: [],
    };
  },
  computed: {
    supportIndexedDbDatabases: () => supportGetSaveKeys,
  },
  mounted: async function () {
    if (!this.supportIndexedDbDatabases) {
      return;
    }
    this.update();
  },
  methods: {
    update: async function () {
      const save_keys = await getSaveKeys();

      const result = [];

      for (const key of save_keys) {
        const info = {
          key,
          size: filesize(await getSaveSize(key)),
        };
        result.push(info);
      }

      this.saves = result;
    },
    clearSaves: async function () {
      for (const save of this.saves) {
        await deleteSave(save.key);
      }
      await this.update();
    },
    deleteSave: async function (key) {
      await deleteSave(key);
      await this.update();
    },
  },
  components: {
    BIconTrash,
    BTooltip,
    BBadge,
    BIconX,
  },
};
</script>