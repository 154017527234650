<template>
  <b-card class="mb-3" title-tag="h5" title="Cloudflare 信息">
    <b-card-text>
      <pre class="cloudflare-info" v-if="loaded">{{ cloudflare_info }}</pre>
      <div v-else>
        <b-icon-arrow-clockwise animation="spin" />
        <span class="ml-2">加载中</span>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BIconArrowClockwise } from "bootstrap-vue";

import { constants } from "@/utils/binary-host";
const { cloudflare_host } = constants;

export default {
  name: "CloudflareHostCard",
  data: function () {
    return {
      cloudflare_info: null,
      loaded: false,
    };
  },
  methods: {
    updateCloudflareInfo: async function () {
      const trace = await fetch(`${cloudflare_host}/cdn-cgi/trace`);
      this.cloudflare_info = await trace.text();
      this.loaded = true;
    },
  },
  mounted() {
    this.updateCloudflareInfo();
  },
  components: {
    BIconArrowClockwise,
  },
};
</script>

<style scoped>
.cloudflare-info {
  white-space: pre-wrap;
  word-break: break-all;
}
</style>