<template>
  <b-card class="mb-3" title-tag="h5" title="下载服务器">
    <b-card-text>
      <tr v-for="host_test in hosts_test" :key="host_test.host">
        <td>
          <code class="nowrap">{{ host_test.label }}</code>
        </td>
        <td class="pl-4">
          <b-icon-arrow-clockwise
            animation="spin"
            v-if="host_test.status === 'loading'"
          />
          <span v-if="host_test.status === 'success'" class="nowrap">
            {{ host_test.latency }} ms
          </span>
          <span v-if="host_test.status === 'error'">
            <span>连接失败</span>
            <b-icon-x variant="danger" />
          </span>
        </td>
      </tr>

      <div class="mt-3">
        <div class="mb-1">服务器选择</div>
        <b-form-select v-model="host_selected" :options="host_options" />
      </div>

      <div class="mt-1">
        <b-form-checkbox
          id="enable-host-autoselect"
          v-model="enable_host_autoselect"
          name="enable-host-autoselect"
        >
          是否自动选择下载服务器
        </b-form-checkbox>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BFormSelect, BFormCheckbox } from "bootstrap-vue";
import { BIconArrowClockwise, BIconX } from "bootstrap-vue";
import {
  getBestHost,
  getLatency,
  setBestHost,
  getAutoTest,
  setAutoTest,
  constants,
} from "@/utils/binary-host";

export default {
  name: "BinaryHostCard",
  data: function () {
    return {
      hosts_test: constants.game_binaries_hosts.map((host) => ({
        host,
        label: constants.game_binaries_host_labels[host],
        status: "loading",
        latency: -1,
      })),
      host_selected: null,
      enable_host_autoselect: true,
    };
  },
  mounted: function () {
    this.updateAllLatencty();
    this.updateSelectedHost();
    this.updateHostAutoSelect();
  },
  methods: {
    updateLatency: async function (host_test) {
      try {
        const latency = await getLatency(host_test.host);
        host_test.latency = latency;
        host_test.status = "success";
      } catch {
        host_test.status = "error";
      }
    },
    updateAllLatencty: async function () {
      for (const host_test of this.hosts_test) {
        this.updateLatency(host_test);
      }
    },
    updateSelectedHost: async function () {
      this.host_selected = await getBestHost();
    },
    updateHostAutoSelect: async function () {
      this.enable_host_autoselect = await getAutoTest();
    },
  },
  watch: {
    host_selected: async function (new_host, oldValue) {
      if (oldValue === null) {
        return;
      }
      this.enable_host_autoselect = false;
      await setBestHost(new_host);
    },
    enable_host_autoselect: async function (value) {
      await setAutoTest(value);
    },
  },
  components: {
    BIconArrowClockwise,
    BIconX,
    BFormSelect,
    BFormCheckbox,
  },
  computed: {
    host_options: () =>
      constants.game_binaries_hosts.map((host) => ({
        text: constants.game_binaries_host_labels[host],
        value: host,
      })),
  },
};
</script>

<style scoped>
.break-all {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}
</style>