<template>
  <b-container class="mt-3">
    <h2>设置</h2>
    <SystemInfoCard />
    <CacheStorageCard />
    <JsDosSaveCard />
    <EmularitySaveCard />
    <ObjectStorageHostCard />
    <BinaryHostCard />
    <CloudflareHostCard />
  </b-container>
</template>

<script>
import SystemInfoCard from "./SystemInfoCard";
import CacheStorageCard from "./CacheStorageCard"
import JsDosSaveCard from "./JsDosSaveCard"
import EmularitySaveCard from "./EmularitySaveCard"
import BinaryHostCard from "./BinaryHostCard"
import ObjectStorageHostCard from "./ObjectStorageHostCard"
import CloudflareHostCard from "./CloudflareHostCard.vue"

export default {
  name: "settings",
  components: {
    SystemInfoCard,
    CacheStorageCard,
    JsDosSaveCard,
    EmularitySaveCard,
    BinaryHostCard,
    CloudflareHostCard,
    ObjectStorageHostCard
  },
  metaInfo: {
    title: "设置",
    meta: [
      {
        name: "description",
        content: "在线 DOS 游戏的设置。",
      },
      { name: "keywords", content: "在线, DOS, 游戏, 在线DOS游戏" },
      { property: "og:title", content: "在线 DOS 游戏的设置" },
      { property: "og:url", content: "https://dos.zczc.cz/settings" },
      {
        property: "og:description",
        content: "在线 DOS 游戏的设置。",
      },
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/settings" }],
  },
};
</script>