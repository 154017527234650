<template>
  <b-card class="mb-3" title-tag="h5" title="系统信息">
    <b-card-text>
      <!-- Frontend Version -->
      <tr v-for="(value, key) in systemStatus" :key="key">
        <td class="nowrap">{{ key }}</td>
        <td class="pl-4">
          <code class="break-all">{{ value }}</code>
        </td>
      </tr>

      <!-- Ping -->
      <tr>
        <td class="nowrap">Ping</td>
        <td class="pl-4">
          <b-icon-arrow-clockwise
            animation="spin"
            v-if="hostLatency.status === 'loading'"
          />
          <span v-if="hostLatency.status === 'success'">
            {{ hostLatency.latency }} ms
          </span>
          <span v-if="hostLatency.status === 'error'">
            <span>连接失败</span>
            <b-icon-x variant="danger" />
          </span>
        </td>
      </tr>
    </b-card-text>
  </b-card>
</template>

<script>
import { version } from "@/utils/constants";
import { BIconArrowClockwise, BIconX } from "bootstrap-vue";
import isElectron from "@/utils/isElectron";
import DetectRTC from "detectrtc";

export default {
  name: "SystemInfoCard",
  data: function () {
    return {
      hostLatency: {
        latency: -1,
        status: "loading",
      },
    };
  },
  components: {
    BIconArrowClockwise,
    BIconX,
  },
  mounted() {
    this.updateHostLatency();
  },
  computed: {
    systemStatus: () => {
      return {
        前端版本: version,
        "Service Worker": "serviceWorker" in navigator,
        IndexedDB: "indexedDB" in window,
        WebAssembly: "WebAssembly" in window,
        WebRTC: DetectRTC.isWebRTCSupported,
        WebSockets: DetectRTC.isWebSocketsSupported,
        CacheStorage: "caches" in window,
        "ES6 module": "noModule" in HTMLScriptElement.prototype,
        Electron: isElectron,
        "User Agent": navigator.userAgent,
      };
    },
  },
  methods: {
    updateHostLatency: async function () {
      try {
        const start = Date.now();
        await fetch(`/?timestamp=${start}`, {
          cache: "no-cache",
          method: "HEAD",
        });
        const latency = Date.now() - start;
        this.hostLatency = {
          latency,
          status: "success",
        };
      } catch {
        this.hostLatency.status = "error";
      }
    },
  },
};
</script>

<style scoped>
.break-all {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}
</style>
