<template>
  <b-card class="mb-3">
    <b-card-title title-tag="h5">
      <span>存档管理</span>
      <small class="ml-2"><b-badge variant="success">js-dos</b-badge></small>
      <b-icon-trash
        scale="0.8"
        class="ml-2"
        id="tooltip-delete-jsdos-save"
        role="button"
        @click="clearSaves"
      />
    </b-card-title>
    <b-tooltip target="tooltip-delete-jsdos-save" triggers="hover">
      删除所有存档
    </b-tooltip>
    <b-card-text>
      <div v-if="saves.length !== 0">
        <tr v-for="save in saves" :key="save.key">
          <td>
            <b-icon-x
              :id="`tooltip-delete-jsdos-${save.key}`"
              scale="0.8"
              class="mr-2"
              @click="deleteSave(save.key)"
              role="button"
            />
            <b-tooltip
              :target="`tooltip-delete-jsdos-${save.key}`"
              triggers="hover"
            >
              删除
            </b-tooltip>
          </td>
          <td>
            {{ save.key }}
          </td>
          <td class="pl-4">
            <code>{{ save.size }}</code>
          </td>
        </tr>
      </div>
      <div v-else>暂无存档</div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BIconTrash, BIconX } from "bootstrap-vue";
import { BTooltip, BBadge } from "bootstrap-vue";
import { getAllSaves, deleteAllSaves, deleteSave } from "@/utils/js-dos";
import filesize from "filesize";

export default {
  name: "JsDosSaveCard",
  data() {
    return {
      saves: [],
    };
  },
  mounted: async function () {
    this.update();
  },
  methods: {
    update: async function () {
      const all_saves = await getAllSaves();
      const saves = Object.keys(all_saves).map((key) => ({
        key,
        size: filesize(all_saves[key].byteLength),
      }));
      this.saves = saves;
    },
    clearSaves: async function () {
      await deleteAllSaves();
      this.update();
    },
    deleteSave: async function (key) {
      await deleteSave(key);
      this.update();
    },
  },
  components: {
    BIconTrash,
    BTooltip,
    BBadge,
    BIconX,
  },
};
</script>