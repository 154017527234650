<template>
  <b-card class="mb-3" title-tag="h5" title="对象存储服务器">
    <b-card-text>
      <tr v-for="host_test in hosts_test" :key="host_test.host">
        <td>
          <code class="nowrap">{{ host_test.label }}</code>
        </td>
        <td class="pl-4">
          <b-icon-arrow-clockwise
            animation="spin"
            v-if="host_test.status === 'loading'"
          />
          <span v-if="host_test.status === 'success'" class="nowrap">
            {{ host_test.latency }} ms
          </span>
          <span v-if="host_test.status === 'error'">
            <span>连接失败</span>
            <b-icon-x variant="danger" />
          </span>
        </td>
      </tr>
    </b-card-text>
  </b-card>
</template>

<script>
import { BIconArrowClockwise, BIconX } from "bootstrap-vue";
import { getLatency, B2HostsLabels, B2Hosts } from "@/utils/objectStorage"

export default {
  name: "ObjectStorageHostCard",
  data: function () {
    return {
      hosts_test: B2Hosts.map((host) => ({
        host,
        label: B2HostsLabels[host],
        status: "loading",
        latency: -1,
      })),
    };
  },
  mounted: function () {
    this.updateAllLatencty();
  },
  methods: {
    updateLatency: async function (host_test) {
      try {
        const latency = await getLatency(host_test.host);
        host_test.latency = latency;
        host_test.status = "success";
      } catch {
        host_test.status = "error";
      }
    },
    updateAllLatencty: async function () {
      for (const host_test of this.hosts_test) {
        this.updateLatency(host_test);
      }
    },
  },
  components: {
    BIconArrowClockwise,
    BIconX,
  },
};
</script>

<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>